/* Global font */
* {
  font-family: sans-serif;
}

/* Table container: center on large screens */
.table-container {
  display: flex;
  justify-content: center;
  /* Horizontally centers the table container */
}

/* Table styling */
#validators {
  border-collapse: collapse;
  margin: 25px 25px;
  font-size: 0.85em;
  min-width: 1400px; /* Large min-width to show many columns */
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

/* Table header row */
#validators thead tr {
  background-color: #5C4033; /* Dark brown header */
  color: white;
  text-align: center;
  font-weight: bold;
}

#validators th,
#validators td {
  padding: 12px 15px;
  text-align: center;
}

/* Default row styling */
#validators tbody tr {
  border-bottom: 1px solid #dddddd;
}

/* Even row shading */
#validators tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

/* Last row border highlight */
#validators tbody tr:last-of-type {
  border-bottom: 2px solid #76D7C4;
}

/* Rows that have issues or missing blocks */
table#validators tbody tr.error {
  background-color: #f3e2dd;
}

/* Row highlight if moniker == "Brightlystake_rpc" */
.decorate {
  background-color: #CEE5D0; /* Light greenish */
}

/* Additional text classes */
.bold {
  font-weight: bolder;
}
.green {
  color: #0da724;
  font-weight: bold;
}
.InActive {
  color: crimson;
  font-weight: bolder;
}
.Active {
  color: #0da724;
  font-weight: bold;
}

/* Section header styling */
.header1 {
  margin-top: 2em;
  text-align: center;
}

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
}
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: rgb(151, 248, 161);
  color: rgb(13, 13, 13);
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
}

/* Tooltip arrow */
.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: white transparent transparent transparent;
}

/* Show tooltip on hover */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

/* When user has just clicked to copy */
.tooltip:hover .tooltiptext.copied {
  background-color: #0da724;
  color: white;
}

/* Buttons for network selection */
.network-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;     /* allow buttons to wrap on smaller screens */
  margin-bottom: 20px; /* spacing below the button group */
}

.network-buttons button {
  border: 1px solid #ccc;
  padding: 8px 16px;
  margin: 5px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Active network button */
.network-buttons button.active {
  background-color: #5C4033; 
  color: white;
  border-color: #ffffff;
}

/* MEDIA QUERY: smaller screens */
@media (max-width: 768px) {
  /* Slightly smaller font on mobile */
  * {
    font-size: 14px;
  }

  /* Let the container scroll horizontally if needed */
  .table-container {
    display: block;
    overflow-x: auto;
  }

  /* Remove min-width constraint so the table can shrink */
  #validators {
    min-width: unset;
    width: 80%;
    box-shadow: none;
  }

  /* Stack the buttons vertically */
  .network-buttons {
    display: block;
  }
  .network-buttons button {
    display: block;
    width: 95%;
    box-sizing: border-box;
  }

  /* If needed, allow row-based or block-based table cells */
  #validators thead tr {
    display: block; 
  }
  #validators th, 
  #validators td {
    white-space: nowrap;
  }
  #validators tbody tr {
    display: block;
  }

  /* Tooltip narrower on mobile */
  .tooltip .tooltiptext {
    margin-left: 0;
    left: 10%;
    right: 10%;
    width: 80%;
  }

  .header1 {
    font-size: 1.2em;
  }

  /* Smaller table cell padding on mobile */
  #validators th, #validators td {
    padding: 8px 10px;
  }

  /* Smaller button font/padding */
  .network-buttons button {
    padding: 8px;
    font-size: 12px;
  }
}
