/* Global font */
* {
  font-family: sans-serif;
}

/* Container for the table and heading */
.table-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
}

/* Section heading */
.header1 {
  margin-top: 1em;
  text-align: center;
}

/* The table itself */
#validators {
  border-collapse: collapse;
  margin: 25px 25px;
  font-size: 0.85em;
  min-width: 1400px; /* ensures there's enough width for columns */
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

/* Table header row */
#validators thead tr {
  background-color: #5C4033; /* brownish color */
  color: white;
  text-align: center;
  font-weight: bold;
}

/* Cells in header/body */
#validators th,
#validators td {
  padding: 12px 15px;
  text-align: center;
}

/* Body rows */
#validators tbody tr {
  border-bottom: 1px solid #dddddd;
}

/* Even row shading */
#validators tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

/* Last row bottom border highlight */
#validators tbody tr:last-of-type {
  border-bottom: 2px solid #76D7C4;
}

/* If you have an "error" row, you can style it like this:
table#validators tbody tr.error {
  background-color: #f3e2dd;
}
*/

/* Catching up = 'False' => green text */
.green {
  color: #0da724;
  font-weight: bold;
}

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer; /* indicates clickable text */
}
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: rgb(151, 248, 161);
  color: rgb(13, 13, 13);
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 9999;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
}

/* Tooltip arrow */
.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: white transparent transparent transparent;
}

/* Show tooltip on hover */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

/* 'Copied!' style */
.tooltip:hover .tooltiptext.copied {
  background-color: #0da724;
  color: white;
}

/* Network/Version buttons */
.network-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.network-buttons button {
  border: 1px solid #ccc;
  padding: 8px 16px;
  margin: 5px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.network-buttons button.active {
  background-color: #5C4033;
  color: white;
  border-color: #ffffff;
}

/* MEDIA QUERY for smaller screens */
@media (max-width: 768px) {
  /* Let table scroll horizontally if needed */
  #validators {
    min-width: unset;
    width: 80%;
    margin: 0 auto;
    box-shadow: none; /* optional: remove shadow on mobile */
  }

  /* Stack the filter buttons vertically */
  .network-buttons {
    display: block;
  }
  .network-buttons button {
    display: block;
    width: 95%;
    box-sizing: border-box;
  }

  #validators th,
  #validators td {
    white-space: nowrap;
    padding: 8px 10px; /* smaller padding */
  }

  /* Adjust tooltip for smaller screens */
  .tooltip .tooltiptext {
    margin-left: 0;
    left: 10%;
    right: 10%;
    width: 80%;
  }
  
  .header1 {
    font-size: 1.2em;
  }
}
