/* Container for everything */
.container {
  max-width: 1200px;
  margin: 2rem auto; /* center horizontally, add vertical space */
  padding: 0 1rem;
  text-align: center; /* center the heading text */
}

/* Heading style */
.heading {
  margin-bottom: 2rem;
}
.heading h4 {
  font-size: 1.5rem;
  font-weight: bold;
}

/* ROW: flex container to hold the cards side by side */
.row {
  display: flex;
  flex-wrap: wrap; /* wrap cards on smaller screens */
  gap: 1rem;
  justify-content: center; /* center them horizontally */
}

/* 
  Card styling 
  We'll add an animation to fade/slide each card in from below 
*/
.card {
  background-color: #fff;
  border: 1px solid #ccc;
  width: 250px;           /* fixed width for consistency */
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  /* Animation: fade in + slide up */
  opacity: 0; /* start invisible */
  transform: translateY(20px); /* start slightly below */
  animation: fadeInUp 0.8s ease forwards; 
  /* "forwards" keeps the final state (opacity:1, translateY(0)) */

  /* Hover transition (scale + shadow) */
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

/* Card hover effect: scale up slightly, add shadow */
.card:hover {
  transform: scale(1.03);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}

/* Card header styling */
.card-header {
  background-color: #5C4033;
  color: #fff;
  padding: 1rem;
}
.card-header h3 {
  margin: 0;
  font-size: 1.1rem;
  font-weight: bolder;
}

/* Card body */
.card-body {
  padding: 1rem;
}

/* Link button */
.btn {
  display: inline-block;
  background-color: #5C4033;
  color: #fff;
  text-decoration: none;
  padding: 0.6rem 1rem;
  border-radius: 4px;
  font-weight: bold;
  margin-top: 1rem;
  transition: background-color 0.2s ease-in-out;
}

.btn:hover {
  background-color: #803C1F; /* slightly darker shade on hover */
}

/* Keyframe for fadeInUp animation */
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive: on small screens, let the cards fill width if needed */
@media (max-width: 600px) {
  .card {
    width: calc(100% - 2rem); /* almost full width */
    margin: 0 auto;
  }
}
