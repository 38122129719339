/****************************
 * Header.css 
 * Styles the Header component’s nav bar 
 ****************************/

/* The parent container for the nav */
.header-nav {
  background-color: #5C4033;   /* The brownish header color */
  width: 100%;
}

/* Remove default list styling and use flex for horizontal layout */
.header-nav ul {
  margin: 0;
  padding: 0;
  list-style: none;

  display: flex;          /* enables flex layout */
  align-items: center;    /* vertically center the items */
  justify-content: flex-start; /* brand at left, other items to the right */
}

/* The brand (left-most item) */
.header-nav .brand {
  margin-right: auto;     /* pushes other nav items to the right side */
}

/* Links (common styles) */
.header-nav li a {
  display: block;
  color: white;
  text-decoration: none;
  padding: 14px 16px;
  font-size: larger;
  font-weight: bolder;
  transition: background-color 0.2s ease-in-out;
}

/* Hover effect */
.header-nav li a:hover {
  background-color: rgba(250, 116, 89, 0.781);
  border-radius: 4px; /* optional rounding on hover */
}

/* Active link styling */
.header-nav a.active {
  /* If you want to highlight the active link differently, add style here */
  /* For example: */
  background-color: #5C4033; /* A darker shade of brown */
  border-radius: 4px;
}
